export * from './require-shim';
export * from './Spine';
export * from './SpineDebugRenderer';
export * from './SpineTexture';
export * from './SlotMesh';
export * from './DarkSlotMesh';
export * from './assets/atlasLoader';
export * from './assets/skeletonLoader';
export * from './darkTintMesh/DarkTintBatchGeom';
export * from './darkTintMesh/DarkTintGeom';
export * from './darkTintMesh/DarkTintMaterial';
export * from './darkTintMesh/DarkTintMesh';
export * from './darkTintMesh/DarkTintRenderer';
export * from "@kimarisgg/spine-core";


import './assets/atlasLoader'; // Side effects install the loaders into pixi
import './assets/skeletonLoader'; // Side effects install the loaders into pixi
